import React, { useEffect, useState } from "react";
import ModalBasic from "../../components/infrastructure/Modals/ModalBasic";
import { useFormik } from "formik";
import { authAxiosInstance } from "../../utils/axiosConfig";
import PrimaryButton from "../../components/infrastructure/Buttons/PrimaryButton";
import FormikSelectGroup from "../../components/formik/FormikSelectGroup";
import { LEAVE_STATUS } from "../../utils/dropdownOptions";
import { useDispatch, useSelector } from "react-redux";
import {
  fetchLeave,
  fetchLeaves,
  getLeaves,
} from "../../app/reducers/Leaves/leaveSlice";
import { ClipLoader } from "react-spinners";

const UpdateLeaveStatusModal = ({
  updateLeaveStatusModalOpen,
  setUpdateLeaveStatusModalOpen,
  leaveId,
}) => {
  const { elementEditData, editDataLoading } = useSelector(getLeaves);
  const [leaveStatusLoading, setLeaveStatusLoading] = useState(false);
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(fetchLeave({ _id: leaveId, populate: true }));
  }, [leaveId]);

  const formik = useFormik({
    initialValues: {
      leave: "",
    },
    onSubmit: async (values, { resetForm }) => {
      try {
        setLeaveStatusLoading(true);
        const resp = await authAxiosInstance.post("leave/updateStatus", {
          status: values.leave,
          id: leaveId,
        });
        if (resp) {
          await dispatch(fetchLeave({ _id: leaveId, populate: true }));
        }
        resetForm();
        setUpdateLeaveStatusModalOpen(false);
        dispatch(fetchLeaves({ populate: true }));
        setLeaveStatusLoading(false);
      } catch (error) {
        console.log(error);
      } finally {
        setLeaveStatusLoading(false);
      }
    },
  });

  console.log(elementEditData, "element");
  return (
    <>
      <ModalBasic
        title={"Update Leave Status"}
        modalOpen={updateLeaveStatusModalOpen}
        setModalOpen={setUpdateLeaveStatusModalOpen}
      >
        {editDataLoading || leaveStatusLoading ? (
          <ClipLoader />
        ) : (
          <form
            onSubmit={formik.handleSubmit}
            className="flex flex-col gap-4 p-4 pb-5"
          >
            <h1 className="text text-xl bold">
              Current Status : {elementEditData?.status.toUpperCase()}
            </h1>
            <FormikSelectGroup
              formik={formik}
              name="leave"
              options={LEAVE_STATUS}
            />
            <div>
              <PrimaryButton type="submit">Submit</PrimaryButton>
            </div>
          </form>
        )}
      </ModalBasic>
    </>
  );
};

export default UpdateLeaveStatusModal;
