import React, { useEffect, useState } from "react";
import { Outlet, useLocation } from "react-router-dom";
import Header from "../../partials/Header";
import Sidebar from "../../partials/SideBar/Sidebar";
import MediaLibraryAssets from "../../pages/MediaLibrary/MediaLibraryAssets";

const RouteWithHeaderAndSidebar = () => {
  const [sidebarOpen, setSidebarOpen] = useState(false);
  const location = useLocation();

  useEffect(() => {
    console.log("sidebar open", sidebarOpen);
    setSidebarOpen(!setSidebarOpen);
  }, [location]);

  return (
    <div className="flex h-screen overflow-hidden">
      {" "}
      <MediaLibraryAssets />
      <Sidebar sidebarOpen={sidebarOpen} setSidebarOpen={setSidebarOpen} />{" "}
      {/*  Site header */}
      {/* Content area */}
      <div
        id="overflow-div"
        className="relative flex flex-col flex-1 overflow-y-auto overflow-x-hidden"
      >
        <Header sidebarOpen={sidebarOpen} setSidebarOpen={setSidebarOpen} />
        <main className="relative">
          <Outlet />
        </main>
      </div>
    </div>
  );
};

export default RouteWithHeaderAndSidebar;
