import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { logout, getAuth } from "../../app/reducers/Auth/authSlice";

const Logout = () => {
  const { loading } = useSelector(getAuth);

  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(logout());
  }, []);
  return <>{loading ? <h1>loading</h1> : <h1>Logging out</h1>}</>;
};

export default Logout;
