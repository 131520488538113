import { createSlice } from "@reduxjs/toolkit";
import { generateStateSingle } from "../../generators/generateState";
import {
  createData,
  deleteData,
  editData,
  fetchData,
  fetchEditData,
} from "../../generators/generateThunks";

const SLICE_NAME = "leave";

export const fetchLeaves = fetchData.generateThunk(SLICE_NAME, SLICE_NAME);
export const fetchLeave = fetchEditData.generateThunk(SLICE_NAME, SLICE_NAME);

const submissionReducer = createSlice({
  initialState: generateStateSingle(SLICE_NAME),
  name: SLICE_NAME,
  extraReducers: {
    ...fetchData.generateExtraReducers(fetchLeaves, SLICE_NAME),
    ...fetchEditData.generateExtraReducers(fetchLeave, SLICE_NAME),
  },
});

export const getLeaves = (state) => state.leaves;

export default submissionReducer.reducer;
