import { createSlice } from "@reduxjs/toolkit";

const loadingSlice = createSlice({
  name: "globalLoading",
  initialState: {
    globalLoading: false,
  },
  reducers: {
    setLoading: (state, action) => {
      state.globalLoading = action.payload;
    },
  },
});

export const { setLoading } = loadingSlice.actions;
export const getGlobalLoading = (state) => state.globalLoading;

export default loadingSlice.reducer;
