import React, { useCallback, useEffect, useMemo, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import PrimaryButton from "../../components/infrastructure/Buttons/PrimaryButton";
import PaginationClassic from "../../components/infrastructure/pagination/PaginationClassic";
import TableWithHeadingAndSearch from "../../components/Table/TableWithHeadingAndSearch";
import {
  fetchSubmissions,
  getSubmissions,
} from "../../app/reducers/Submission/submissionSlice";
import moment from "moment";
import ModalBasic from "../../components/infrastructure/Modals/ModalBasic";
import MapContainer from "../MapContainer";
import QueryString from "qs";
import { saveAs } from "file-saver";
import { axiosInstance } from "../../utils/axiosConfig";

const ViewSubmission = () => {
  const { projectName, areaCheck, subProject } = useParams();
  const [page, setPage] = useState(1);
  const [mapModal, setMapModal] = useState(false);
  const [position, setPosition] = useState(null);
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const { submission, loading } = useSelector(getSubmissions);

  const debouncedSearch = useCallback(
    _.debounce((search) => {
      dispatch(fetchSubmissions({ search: search }));
    }, 300),
    []
  );

  const cols = [
    {
      Header: "Submission Id",
      accessor: "_id",
    },
    {
      Header: "Name",
      accessor: "projectName",
    },
    {
      Header: "Submitted By",
      Cell: ({ row }) => {
        return (
          row.original.submittedBy?.firstName +
          " " +
          row.original.submittedBy?.lastName
        );
      },
    },
    {
      Header: "Submitted Date",
      Cell: ({ row }) => {
        return moment(row.original.createdAt).format("DD-MM-YYYY");
      },
    },
    {
      Header: "Client First Name",
      accessor: "clientFirstName",
    },
    {
      Header: "Client Last Name",
      accessor: "clientLastName",
    },
    {
      Header: "Client Email",
      accessor: "clientEmail",
    },
    {
      Header: "Area of Checking",
      accessor: "areaOfChecking",
    },
    {
      Header: "Sub Project",
      accessor: "subProject",
    },
    {
      Header: "Location",
      Cell: ({ row }) => {
        return (
          <div className="flex gap-3 items-center">
            <PrimaryButton
              onClick={(e) => {
                e.stopPropagation();
                let data = {
                  lat: row.original?.latitude,
                  lng: row.original?.longitude,
                };
                setPosition(data);
                setMapModal(true);
              }}
            >
              View Map
            </PrimaryButton>
          </div>
        );
      },
    },
    {
      Header: "Action",
      Cell: ({ row }) => {
        return (
          <div className="flex gap-3 items-center">
            <PrimaryButton
              onClick={async (e) => {
                const string = QueryString.stringify({ id: row.original?._id });
                const response = await axiosInstance.get(
                  `/submission/pdf?${string}`,
                  { responseType: "blob" }
                );
                saveAs(response.data);
              }}
            >
              Download Pdf
            </PrimaryButton>
          </div>
        );
      },
    },
  ];
  useEffect(() => {
    dispatch(
      fetchSubmissions({
        $and: [
          { areaOfChecking: areaCheck },
          { projectName: projectName },
          { subProject: subProject },
        ],
        populate: true,
      })
    );
  }, [page]);
  const data = useMemo(
    () => (submission?.docs ? submission.docs : []),
    [submission]
  );
  return (
    <>
      {/* {/* {mapModal && <div></div>} */}
      {mapModal && (
        <ModalBasic
          modalOpen={mapModal}
          setModalOpen={setMapModal}
          title={"Map"}
        >
          <MapContainer position={position} />
        </ModalBasic>
      )}
      <div className="p-4">
        <TableWithHeadingAndSearch
          loading={loading}
          data={data}
          searchFunction={debouncedSearch}
          columns={cols}
        />
        <PaginationClassic setPage={setPage} paginationDetails={submission} />
      </div>
    </>
  );
};

export default ViewSubmission;
