import { FieldArray, FormikProvider, useFormik } from "formik";
import React from "react";
import { useEffect } from "react";
import { X } from "react-feather";
import { useDispatch, useSelector } from "react-redux";
import { ClipLoader } from "react-spinners";
import { array, object, string } from "yup";
import { fetchUsers, getUsers } from "../../app/reducers/Users/usersSlice";
import {
  editProject,
  fetchProject,
  fetchProjects,
  getProjects,
} from "../../app/reducers/Project/projectSlice";
import FormikInputGroup from "../../components/formik/FormikInputGroup";
import FormikMultiSelect from "../../components/formik/FormikMultiSelect";
import PrimaryButton from "../../components/infrastructure/Buttons/PrimaryButton";
import SecondaryButton from "../../components/infrastructure/Buttons/SecondaryButton";
import PageWithCard from "../../components/infrastructure/PageWithCard";
import { generateOptions, generateUserOptions } from "../../utils/Utils";
import { useNavigate, useParams } from "react-router-dom";

const EditProductPage = () => {
  const { id } = useParams();
  const dispatch = useDispatch();
  const navigate = useNavigate();

  useEffect(() => {
    dispatch(fetchProject({ _id: id }));
  }, [id]);

  const { editDataLoading, elementEditData } = useSelector(getProjects);

  useEffect(() => {
    dispatch(fetchUsers());
  }, []);

  const { users, loading: usersLoading } = useSelector(getUsers);

  const formik = useFormik({
    enableReinitialize: true,
    initialValues: elementEditData,
    validationSchema: object({
      client_firstName: string().required(),
      client_lastName: string().required(),
      client_email: string().required(),
      name: string().required(),
      areaCheck: array(),
      subProject: array(),
      assignedUsers: array(),
    }),
    onSubmit: async (values, { resetForm }) => {
      if (values.subProject.length < 1) {
        alert("Please add atleast one tower/wing");
        return;
      }
      await dispatch(editProject({ ...values }));
      navigate("/project/viewProject");
    },
  });

  return (
    <PageWithCard heading="Add Project">
      {editDataLoading ? (
        <ClipLoader />
      ) : (
        <form onSubmit={formik.handleSubmit} className="flex flex-col gap-4">
          <FormikInputGroup name="name" label="Name" formik={formik} required />
          <FormikInputGroup
            name="client_firstName"
            label="Client First Name"
            formik={formik}
            required
          />
          <FormikInputGroup
            name="client_lastName"
            label="Client Last Name"
            formik={formik}
            required
          />
          <FormikInputGroup
            name="client_email"
            label="Client Email"
            formik={formik}
            required
          />
          <FormikProvider value={formik}>
            <FieldArray
              name="areaCheck"
              render={(arrayHelpers) => {
                return (
                  <div className="flex flex-col gap-2">
                    <p>Area of checking</p>
                    <div>
                      {formik.values?.areaCheck.map((ele, index) => (
                        <div className="relative p-3" key={index}>
                          <div className="absolute left-0 top-0">
                            <X
                              size={15}
                              color="red"
                              onClick={() => {
                                arrayHelpers.remove(index);
                              }}
                            />
                          </div>
                          <FormikInputGroup
                            name={`areaCheck.${index}`}
                            formik={formik}
                            required
                          />
                        </div>
                      ))}
                    </div>
                    <div>
                      <SecondaryButton
                        onClick={() => {
                          arrayHelpers.push(null);
                        }}
                        type="button"
                      >
                        Add Area of Checking
                      </SecondaryButton>
                    </div>
                  </div>
                );
              }}
            />
          </FormikProvider>
          <FormikProvider value={formik}>
            <FieldArray
              name="subProject"
              render={(arrayHelpers) => {
                return (
                  <div className="flex flex-col gap-2">
                    <p>Sub Project</p>
                    <div>
                      {formik.values?.subProject.map((ele, index) => (
                        <div className="relative p-3" key={index}>
                          <div className="absolute left-0 top-0">
                            <X
                              size={15}
                              color="red"
                              onClick={() => {
                                arrayHelpers.remove(index);
                              }}
                            />
                          </div>
                          <FormikInputGroup
                            name={`subProject.${index}`}
                            formik={formik}
                            required
                          />
                        </div>
                      ))}
                    </div>
                    <div>
                      <SecondaryButton
                        onClick={() => {
                          arrayHelpers.push(null);
                        }}
                        type="button"
                      >
                        Add Area of Sub Project
                      </SecondaryButton>
                    </div>
                  </div>
                );
              }}
            />
          </FormikProvider>
          {usersLoading ? (
            <ClipLoader />
          ) : (
            <FormikMultiSelect
              formik={formik}
              label="Assigned Users"
              name="assignedUsers"
              options={generateUserOptions({
                array: users?.docs ? users.docs : [],
                valueField: "_id",
                labelField: "firstName",
              })}
            />
          )}
          <div>
            <PrimaryButton type="submit">Submit</PrimaryButton>
          </div>
        </form>
      )}
    </PageWithCard>
  );
};

export default EditProductPage;
