import React from "react";

import PrimaryButton from "../../components/infrastructure/Buttons/PrimaryButton";
import EmailEditor from "react-email-editor";
import { useRef } from "react";
import { useDispatch } from "react-redux";
import { createEmail } from "../../app/reducers/Emails/emailSlice";
import { useNavigate } from "react-router-dom";

const AddEmails = () => {
  const navigate = useNavigate();
  const emailEditorRef = useRef(null);
  const dispatch = useDispatch();
  const saveDesign = () => {
    let designJson = null;
    let designHtml = null;
    const name = window.prompt("Please enter name of this template");
    console.log(name);
    if (name) {
      emailEditorRef.current.editor.saveDesign((design) => {
        designJson = design;
        emailEditorRef.current.editor.exportHtml(async (html) => {
          designHtml = html;
          console.log({ design, html: html.html });
          await dispatch(
            createEmail({
              design: JSON.stringify(design),
              html: html.html,
              name,
            })
          );
          navigate("/emails/viewEmails");
        });
      });
    } else {
      alert("name of template is required");
    }
  };
  return (
    <div className="relative">
      <div className="fixed flex items-center gap-4 h-[70px] bg-white w-full">
        <PrimaryButton
          onClick={() => {
            saveDesign();
          }}
        >
          Save Design
        </PrimaryButton>
      </div>
      <div>
        <EmailEditor
          style={{ height: "calc(100vh - 50px)", marginTop: "70px" }}
          ref={emailEditorRef}
        />
        ;
      </div>
    </div>
  );
};

export default AddEmails;
