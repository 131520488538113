import React from "react";

import PrimaryButton from "../../components/infrastructure/Buttons/PrimaryButton";
import EmailEditor from "react-email-editor";
import { useRef } from "react";
import { useDispatch } from "react-redux";
import { createEmail, editEmail } from "../../app/reducers/Emails/emailSlice";
import { useLocation, useNavigate } from "react-router-dom";
import { useEffect } from "react";
import { useState } from "react";
import SecondaryButton from "../../components/infrastructure/Buttons/SecondaryButton";

const EditEmails = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const [showData, setShowData] = useState(false);
  const [editId, setEditId] = useState("");

  const emailEditorRef = useRef(null);
  const dispatch = useDispatch();
  const saveDesign = () => {
    let designJson = null;
    let designHtml = null;
    const name = window.prompt("Please enter name of this template");
    console.log(name);

    emailEditorRef.current.editor.saveDesign((design) => {
      designJson = design;
      emailEditorRef.current.editor.exportHtml(async (html) => {
        designHtml = html;
        const editObject = {
          design: JSON.stringify(design),
          html: html.html,
          _id: editId,
        };
        if (name) {
          editObject.name = name;
        }
        await dispatch(editEmail(editObject));
        navigate("/emails/viewEmails");
      });
    });
  };
  const onLoad = () => {
    console.log("onLoad");

    // emailEditorRef.current.editor.addEventListener(
    //   'design:loaded',
    //   onDesignLoad
    // );

    emailEditorRef.current.editor.loadDesign(JSON.parse(location.state.design));
  };
  useEffect(() => {
    if (location?.state?.design) {
      setShowData(true);
      setEditId(location.state._id);
      // console.log(JSON.parse(location.state.design));
      // emailEditorRef?.current?.editor?.loadDesign(location.state.design);
    } else {
      setShowData(false);
    }
  }, [emailEditorRef]);
  return (
    <div className="relative">
      {showData ? (
        <>
          <div className="fixed flex items-center gap-4 h-[70px] bg-white w-full">
            <PrimaryButton
              onClick={() => {
                saveDesign();
              }}
            >
              Edit Design
            </PrimaryButton>
            <SecondaryButton
              onClick={() => {
                navigate("/emails/viewEmails");
              }}
            >
              Cancel
            </SecondaryButton>
          </div>
          <div>
            <EmailEditor
              style={{ height: "calc(100vh - 50px)", marginTop: "70px" }}
              ref={emailEditorRef}
              onReady={onLoad}
            />
            ;
          </div>
        </>
      ) : (
        <h1>No Email selected please select once again</h1>
      )}
    </div>
  );
};

export default EditEmails;
