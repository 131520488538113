import React from "react";
import {
  Briefcase,
  Edit,
  FileMinus,
  Home,
  Mail,
  Monitor,
  User,
  UserPlus,
} from "react-feather";
import SidebarGroup from "../../components/infrastructure/SidebarGroup";
import SidebarSingle from "../../components/infrastructure/SidebarSingle";
import SidebarTopText from "../../components/infrastructure/SidebarTopText";

const SidebarLinks = ({ sidebarOpen, sidebarExpanded, setSidebarExpanded }) => {
  return (
    <ul className="mt-3">
      {/* Dashboard */}
      <SidebarTopText text="Application" sidebarOpen={sidebarOpen} />
      <SidebarSingle
        allowedRoles={[
          "admin",
          "Purchase Admin",
          "Sales Admin",
          "Purchase Executive",
          "Sales Executive",
        ]}
        activePath="/"
        name="Dashboard"
        Icon={<Monitor />}
        sidebarOpen={sidebarOpen}
      />
      <SidebarSingle
        allowedRoles={["admin"]}
        activePath="/users/addUser"
        name="Add User"
        Icon={<UserPlus />}
        sidebarOpen={sidebarOpen}
      />
      <SidebarSingle
        allowedRoles={["admin"]}
        activePath="/users/viewUsers"
        name="View Users"
        Icon={<User />}
        sidebarOpen={sidebarOpen}
      />
      <SidebarSingle
        allowedRoles={["admin"]}
        activePath="/users/editUser"
        name="Edit User"
        Icon={<Edit />}
        sidebarOpen={sidebarOpen}
      />
      <SidebarGroup
        links={[
          {
            label: "Add Emails",
            link: "/emails/addEmails",
            allowedRoles: ["admin"],
          },
          {
            label: "View Emails",
            link: "/emails/viewEmails",
            allowedRoles: ["admin"],
          },
        ]}
        heading="Emails"
        Icon={<Mail />}
        sidebarExpanded={sidebarExpanded}
        setSidebarExpanded={setSidebarExpanded}
      />{" "}
      <SidebarGroup
        links={[
          {
            label: "Add Project",
            link: "/project/addProject",
            allowedRoles: ["admin"],
          },
          {
            label: "View Project",
            link: "/project/viewProject",
            allowedRoles: ["admin"],
          },
          {
            label: "Edit Project",
            link: "/project/editProject",
            allowedRoles: ["admin"],
          },
        ]}
        heading="Projects"
        Icon={<Home />}
        sidebarExpanded={sidebarExpanded}
        setSidebarExpanded={setSidebarExpanded}
      />{" "}
      <SidebarGroup
        links={[
          {
            label: "View Submission",
            link: "/submission/project",
            allowedRoles: ["admin"],
          },
          {
            label: "View Leaves",
            link: "/viewLeaves",
            allowedRoles: ["admin"],
          },
        ]}
        heading="Submission"
        Icon={<FileMinus />}
        sidebarExpanded={sidebarExpanded}
        setSidebarExpanded={setSidebarExpanded}
      />{" "}
      <SidebarSingle
        allowedRoles={["admin"]}
        activePath="/mediaLibrary"
        name="Media Library"
        Icon={<Briefcase />}
        sidebarOpen={sidebarOpen}
      />
    </ul>
  );
};

export default SidebarLinks;
