export const GENDER = [
  { label: "male", value: "male" },
  { label: "female", value: "female" },
];
export const ROLES = [
  { label: "Admin", value: "admin" },
  { label: "Attendee", value: "attendee" },
  { label: "Crew", value: "crew" },
];
export const VOLUME = [
  { label: "High", value: "high" },
  { label: "Medium", value: "medium" },
  { label: "Low", value: "low" },
];
export const FORM = [
  { label: "Liquid", value: "LIQ" },
  { label: "Powder", value: "POW" },
  { label: "SaltFree", value: "SF" },
  { label: "Press Cake", value: "PC" },
  { label: "Granules", value: "GR" },
  { label: "Crystal", value: "CR" },
];

export const TSHIRT_SIZE = [
  { label: "S", value: "S" },
  { label: "M", value: "M" },
  { label: "L", value: "L" },
  { label: "XL", value: "XL" },
  { label: "Xxl", value: "Xxl" },
  { label: "XXxl", value: "XXxl" },
];
export const FOOD_PREFERENCE = [
  { label: "Veg", value: "Veg" },
  { label: "Non veg", value: "Non veg" },
  { label: "Jain", value: "Jain" },
];
export const MUSIC_PREFERENCE = [
  { label: "Rock", value: "Rock" },
  { label: "Jazz", value: "Jazz" },
  { label: "Bollywood party", value: "Bollywood party" },
  { label: "Sufi", value: "Sufi" },
  { label: "Ghazal", value: "Ghazal" },
];
export const CREW_MEMBERS = [
  { label: "0", value: "0" },
  { label: "1", value: "1" },
  { label: "2", value: "2" },
];
export const TITLE = [
  { label: "Mr.", value: "Mr." },
  { label: "Ms.", value: "Ms." },
  { label: "Mrs.", value: "Mrs." },
];

export const DEPARTMENTS = [
  { label: "LIAISONING", value: "LIAISONING" },
  { label: "ACCOUNTS", value: "ACCOUNTS" },
  { label: "DRAFTING", value: "DRAFTING" },
  { label: "CIVIL ENGINEERING", value: "CIVIL ENGINEERING" },
  { label: "INTERIOR DESIGNING", value: "INTERIOR DESIGNING" },
  { label: "URBAN/REGIONAL DESIGNING", value: "URBAN/REGIONAL DESIGNING" },
  { label: "OFFICE MANAGEMENT AND HR", value: "OFFICE MANAGEMENT AND HR" },
  { label: "DESIGNING/ARCHITECTURE", value: "DESIGNING/ARCHITECTURE" },

  { label: "OFFICE CARE", value: "OFFICE CARE" },
  { label: "HOD (DESIGNING/ARCHITECT)", value: "HOD (DESIGNING/ARCHITECT)" },
  { label: "ARCHITECT", value: "ARCHITECT" },
  { label: "DESIGNING", value: "DESIGNING" },
  { label: "RECEPTION", value: "RECEPTION" },
];

export const DESIGNATIONS = [
  { label: "JUNIOR ARCHITECT", value: "JUNIOR ARCHITECT" },
  { label: "FRONT DESK", value: "FRONT DESK" },
  { label: "CAD TECHNICIAN", value: "CAD TECHNICIAN" },
  { label: "HEAD DRAFTING DEPARTMENT", value: "HEAD DRAFTING DEPARTMENT" },
  { label: "OFFICE COORDINATOR", value: "OFFICE COORDINATOR" },
  { label: "URBAN DESIGNER", value: "URBAN DESIGNER" },
  { label: "CAD OPERATOR", value: "CAD OPERATOR" },
  { label: "LIAISON PERSONAL", value: "LIAISON PERSONAL" },
  { label: "SOFTWARE ENGINEER", value: "SOFTWARE ENGINEER" },
  { label: "SITE COORDINATOR", value: "SITE COORDINATOR" },
  { label: "COORDINATOR", value: "COORDINATOR" },
  { label: "LIAISON WORK COORDINATOR", value: "LIAISON WORK COORDINATOR" },
  { label: "OFFICE CARETAKER", value: "OFFICE CARETAKER" },
  { label: "PRINCIPAL ARCHITECT", value: "PRINCIPAL ARCHITECT" },
  { label: "MEP DESIGNER", value: "MEP DESIGNER" },
  { label: "ACCOUNTANT", value: "ACCOUNTANT" },
  { label: "HR & ADMIN", value: "HR & ADMIN" },
  { label: "CIVIL ENGINEER", value: "CIVIL ENGINEER" },
  { label: "SENIOR ARCHITECT", value: "SENIOR ARCHITECT" },
  { label: "LANDSCAPE DESIGNER", value: "LANDSCAPE DESIGNER" },
  { label: "DESIGNER / ARCHITECT", value: "DESIGNER / ARCHITECT" },
  { label: "FRONT OFFICE EXECUTIVE", value: "FRONT OFFICE EXECUTIVE" },
  { label: "TRAINEE", value: "TRAINEE" },
  { label: "DRIVER", value: "DRIVER" },
  { label: "PROJECT MANAGEMENT", value: "PROJECT MANAGEMENT" },
  { label: "COORDINATOR ARCHITECT", value: "COORDINATOR ARCHITECT" },
  { label: "STRUCTURAL ENGINEER", value: "STRUCTURAL ENGINEER" },
  { label: "TELEPHONE OPERATOR", value: "TELEPHONE OPERATOR" },
  { label: "SITE ENGINEER", value: "SITE ENGINEER" },
];

export const AREA_OF_CHECKING = [
  { label: "EXCAVATION", value: "EXCAVATION" },
  { label: "LINE OUT", value: "LINE OUT" },
  { label: "CENTERLINE", value: "CENTERLINE" },
  { label: "FOOTING", value: "FOOTING" },
  { label: "COLUMN STARTER", value: "COLUMN STARTER" },
  { label: "SLAB", value: "SLAB" },
  { label: "SITE VISIT & SITE PHOTOGRAPHS", value: "SITE VISIT PHOTOGRAPH" },
  { label: "OTHERS", value: "OTHERS" },
];
export const LEAVE_STATUS = [
  { label: "Pending", value: "pending" },
  { label: "Rejected", value: "rejected" },
  { label: "Approved", value: "approved" },
];
export const USER_ROLE = [
  { label: "Admin", value: "admin" },
  { label: "User", value: "user" },
];
