import React from "react";

const SecondaryButton = ({
  children,
  className,
  type = "button",
  ...props
}) => {
  return (
    <button
      className={`btn border-slate-200 hover:border-slate-300 text-indigo-500 ${
        className ? className : ""
      }`}
      type={type}
      {...props}
    >
      {children}
    </button>
  );
};

export default SecondaryButton;
