import moment from "moment";
import React, { useCallback, useEffect, useMemo, useState } from "react";
import { Trash } from "react-feather";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { getMediaLibrary } from "../../app/reducers/MediaLibrary/mediaLibrarySlice";
import {
  deleteProject,
  fetchProjects,
  getProjects,
} from "../../app/reducers/Project/projectSlice";
import PrimaryButton from "../../components/infrastructure/Buttons/PrimaryButton";
import PaginationClassic from "../../components/infrastructure/pagination/PaginationClassic";
import TableWithHeadingAndGlobalSearch from "../../components/Table/TableWithHeadingAndGlobalSearch";

const VIewProject = () => {
  const [page, setPage] = useState(1);
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const { project, loading } = useSelector(getProjects);

  const debouncedSearch = useCallback(
    _.debounce((search) => {
      dispatch(fetchProjects({ search: search }));
    }, 300),
    []
  );

  const cols = [
    {
      Header: "Name",
      accessor: "name",
    },
    {
      Header: "Client First Name",
      accessor: "client_firstName",
    },
    {
      Header: "Client Last Name",
      accessor: "client_lastName",
    },
    {
      Header: "Client Email",
      accessor: "client_email",
    },
    {
      Header: "Assigned User",
      Cell: ({ row }) => {
        if (row?.original?.assignedUsers?.length > 0) {
          const assignedUser = row?.original?.assignedUsers?.map((el, i) => {
            const firstName = el?.firstName;
            const lastName = el?.lastName;
            return (
              <ul key={i}>
                <li>
                  {firstName} <span>{lastName}</span>
                </li>
              </ul>
            );
          });
          return assignedUser;
        }
        return "No User Found";
      },
    },
    {
      Header: "Area of Checking",
      Cell: ({ row }) => {
        console.log(row.original);
        return (
          <ul>
            {row.original.areaCheck && row.original.areaCheck.length > 0
              ? row.original.areaCheck.map((d, i) => <li key={i}>- {d}</li>)
              : "---"}
          </ul>
        );
      },
    },
    {
      Header: "Sub Project",
      Cell: ({ row }) => {
        return (
          <ul>
            {row.original.subProject && row.original.subProject.length > 0
              ? row.original.subProject.map((d, i) => <li key={i}>- {d}</li>)
              : "---"}
          </ul>
        );
      },
    },
    {
      Header: "Action",
      Cell: ({ row }) => {
        return (
          <div className="flex gap-3 items-center">
            <PrimaryButton
              onClick={() => {
                navigate(`/project/editProject/${row.original._id}`);
              }}
            >
              Edit
            </PrimaryButton>
            <Trash
              onClick={async () => {
                await dispatch(deleteProject({ id: row.original._id }));
                dispatch(fetchProjects());
              }}
              color="red"
            />
          </div>
        );
      },
    },
  ];
  useEffect(() => {
    dispatch(fetchProjects({ populate: true }));
  }, [page]);
  const data = useMemo(() => (project?.docs ? project.docs : []), [project]);
  return (
    <div className="p-4">
      <TableWithHeadingAndGlobalSearch
        loading={loading}
        data={data}
        searchFunction={debouncedSearch}
        columns={cols}
      />
      <PaginationClassic setPage={setPage} paginationDetails={project} />
    </div>
  );
};

export default VIewProject;
