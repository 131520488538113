import React from "react";
import PhoneInput from "react-phone-input-2";
import _ from "lodash";

const FormikPhoneInputGroup = ({
  formik,
  label = "",
  required = false,
  name,
  fullWidth,
  ...props
}) => {
  return (
    <div className={fullWidth ? "w-full" : ""}>
      <label className="block text-sm font-medium mb-1" htmlFor={name}>
        {label} {required && <span className="text-rose-500">*</span>}
      </label>
      <PhoneInput
        value={_.at(formik.values, name)[0]}
        onChange={(text) => {
          formik.setFieldValue(name, text);
        }}
        name={name}
        onBlur={formik.handleBlur(name)}
        prefix="+"
        inputProps={{ style: { width: "100%" }, ...props }}
      />
      {formik.errors[name] && formik.touched[name] ? (
        <p className="text-xs text-red-500">{formik.errors[name]}</p>
      ) : null}
    </div>
  );
};

export default FormikPhoneInputGroup;
