import { FieldArray, FormikProvider, useFormik } from "formik";
import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import { ClipLoader } from "react-spinners";
import {
  deleteObject,
  deleteSingleObject,
} from "../../app/reducers/MediaLibrary/mediaLibrarySlice";
import {
  editUser,
  fetchUser,
  getUsers,
} from "../../app/reducers/Users/usersSlice";
import FormikCheckbox from "../../components/formik/FormikCheckbox";
import FormikFileInput from "../../components/formik/FormikFileInput";
import FormikInputDateGroup from "../../components/formik/FormikInputDateGroup";
import FormikInputGroup from "../../components/formik/FormikInputGroup";
import DangerButton from "../../components/infrastructure/Buttons/DangerButton";
import PrimaryButton from "../../components/infrastructure/Buttons/PrimaryButton";
import SecondaryButton from "../../components/infrastructure/Buttons/SecondaryButton";
import PageWithCard from "../../components/infrastructure/PageWithCard";
import FormikSelectGroup from "../../components/formik/FormikSelectGroup";
import { DEPARTMENTS, DESIGNATIONS, USER_ROLE } from "../../utils/dropdownOptions";
import FormikDirectFileUpload from "../../components/formik/FormikDirectFileUpload";
import FormikTextareaGroup from "../../components/formik/FormikTextareaGroup";
import FormikPhoneInputGroup from "../../components/formik/FormikPhoneInputGroup";

const EditUserPage = () => {
  const { id } = useParams();
  const { loading, editDataLoading, elementEditData } = useSelector(getUsers);
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const formik = useFormik({
    enableReinitialize: true,
    initialValues: {
      ...elementEditData,
      password: "",
      alternatePhone: elementEditData?.alternatePhone
        ? elementEditData.alternatePhone
        : "91",
    },
    onSubmit: async (values) => {
      await dispatch(editUser(values));
      navigate("/users/viewUsers");
    },
  });

  useEffect(() => {
    dispatch(fetchUser({ _id: id }));
  }, [id]);

  return (
    <PageWithCard heading="Edit Users">
      {editDataLoading ? (
        <ClipLoader />
      ) : (
        <form className="flex flex-col gap-4" onSubmit={formik.handleSubmit}>
          <FormikInputGroup
            name="_id"
            readOnly
            label="System Id"
            formik={formik}
          />
          <FormikPhoneInputGroup
            name="username"
            formik={formik}
            label="Phone"
            readOnly
          />
          <FormikPhoneInputGroup
            name="alternatePhone"
            formik={formik}
            label="Alternate Phone Number"
          />
          <FormikInputGroup
            name="firstName"
            formik={formik}
            label="First Name"
          />
          <FormikInputGroup name="lastName" formik={formik} label="Last Name" />
          <FormikInputGroup
            name="employeeId"
            formik={formik}
            label="Employee Id"
          />
          <FormikSelectGroup
            name="designation"
            formik={formik}
            label="Designation"
            options={DESIGNATIONS}
          />
          <FormikInputGroup
            name="password"
            type="password"
            formik={formik}
            label="Password"
          />
          <FormikSelectGroup
            name="department"
            formik={formik}
            label="Department"
            options={DEPARTMENTS}
          />
          <FormikInputDateGroup
            name="dateOfBirth"
            formik={formik}
            label="Date Of Birth"
          />
          <FormikInputDateGroup
            name="joiningDate"
            formik={formik}
            label="Date Of Joining"
          />
          <FormikCheckbox name="active" formik={formik} label="Active" />
          <FormikTextareaGroup name="notes" formik={formik} label="Notes" />
          <FormikSelectGroup
            formik={formik}
            label="Role"
            name={'role'}
            options={USER_ROLE}

          />
          {/* <div className="grid grid-cols-2">
            {elementEditData?.identityProof?.map((ele) => (
              <React.Fragment key={ele._id}>
                <a className="text-indigo-500 underline" href={ele.file}>
                  <p>{ele.name}</p>
                </a>
                <div>
                  <DangerButton
                    onClick={async (e) => {
                      const confirm = window.confirm(
                        "Are you sure you want to remove this file"
                      );
                      if (confirm) {
                        const identityProof =
                          elementEditData.identityProof.filter(
                            (data) => data._id !== ele._id
                          );
                        await dispatch(
                          deleteSingleObject({
                            keys: [ele.file],
                          })
                        );
                        await dispatch(
                          editUser({
                            _id: id,
                            identityProof,
                          })
                        );
                        await fetchUser({ _id: id });
                      }
                    }}
                  >
                    Delete
                  </DangerButton>
                </div>
              </React.Fragment>
            ))}
          </div> */}
          <FormikProvider value={formik}>
            <FieldArray
              name="identityProof"
              render={(arrayHelpers) => {
                return (
                  <div className="flex flex-col gap-2 w-full">
                    {formik.values?.identityProof?.map?.((ele, i) => (
                      <div
                        className="grid grid-cols-3 gap-2 items-center"
                        key={i}
                      >
                        <FormikInputGroup
                          name={`identityProof.${i}.name`}
                          formik={formik}
                          required
                          label="Document Name"
                        />
                        <FormikDirectFileUpload
                          name={`identityProof.${i}.file`}
                          formik={formik}
                          required
                          label="File"
                          location={`black_ink/users/${formik.values.username}/`}
                        />
                        <div className="flex items-end h-full">
                          <DangerButton
                            onClick={async (e) => {
                              const confirm = window.confirm(
                                "Are you sure you want to remove this file"
                              );
                              if (confirm) {
                                const identityProof =
                                  elementEditData.identityProof.filter(
                                    (data) => data._id !== ele._id
                                  );
                                if (ele.file)
                                  await dispatch(
                                    deleteSingleObject({
                                      keys: [ele.file],
                                    })
                                  );
                                await dispatch(
                                  editUser({
                                    _id: id,
                                    identityProof,
                                  })
                                );
                                navigate(0);
                              }
                            }}
                          >
                            Delete
                          </DangerButton>
                        </div>
                      </div>
                    ))}
                    <div>
                      <SecondaryButton
                        onClick={() => {
                          arrayHelpers.push({
                            name: "",
                            file: "",
                          });
                        }}
                      >
                        Add Identity Proof
                      </SecondaryButton>
                    </div>
                  </div>
                );
              }}
            />
          </FormikProvider>
          <div>
            <PrimaryButton type="submit">Submit</PrimaryButton>
          </div>
        </form>
      )}
    </PageWithCard>
  );
};

export default EditUserPage;
