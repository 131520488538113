import React from "react";
import { NavLink, useLocation } from "react-router-dom";
import { useSelector } from "react-redux";
import { getAuth } from "../../app/reducers/Auth/authSlice";

const SidebarSingle = ({
  activePath,
  Icon,
  name,
  allowedRoles,
  sidebarOpen,
}) => {
  const { user, jwt } = useSelector(getAuth);
  const location = useLocation();
  const { pathname } = location;
  const checkPathName = () => {
    if (activePath === "/") {
      if (pathname === "/") return true;
      return false;
    }
    return pathname.includes(activePath);
  };
  console.log(sidebarOpen);
  {
    return (
      <>
        {allowedRoles.includes(user.role) && (
          <li
            title={name}
            className={`px-3 py-2 rounded-sm mb-0.5 last:mb-0 ${
              checkPathName() && "bg-slate-900"
            }`}
          >
            <NavLink
              end
              to={activePath}
              className={`block text-slate-200 hover:text-white truncate transition duration-150 ${
                checkPathName() && "hover:text-slate-200"
              }`}
            >
              <div className="flex items-center">
                {Icon}
                <span
                  className={`text-sm font-medium ml-3 sidebar-expanded:block 2xl:opacity-100 duration-200 ${
                    sidebarOpen ? "block" : "hidden"
                  }`}
                >
                  {name}
                </span>
              </div>
            </NavLink>
          </li>
        )}
      </>
    );
  }
};

export default SidebarSingle;
