import { FieldArray, FormikProvider, useFormik } from "formik";
import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { ClipLoader } from "react-spinners";
import { array, object, string } from "yup";
import { createUser, getUsers } from "../../app/reducers/Users/usersSlice";
import FormikCheckbox from "../../components/formik/FormikCheckbox";
import FormikFileInput from "../../components/formik/FormikFileInput";
import FormikInputDateGroup from "../../components/formik/FormikInputDateGroup";
import FormikInputGroup from "../../components/formik/FormikInputGroup";
import FormikPhoneInputGroup from "../../components/formik/FormikPhoneInputGroup";
import DangerButton from "../../components/infrastructure/Buttons/DangerButton";
import PrimaryButton from "../../components/infrastructure/Buttons/PrimaryButton";
import SecondaryButton from "../../components/infrastructure/Buttons/SecondaryButton";
import PageWithCard from "../../components/infrastructure/PageWithCard";
import FormikDirectFileUpload from "../../components/formik/FormikDirectFileUpload";
import { useNavigate } from "react-router-dom";
import {
  deleteObject,
  deleteSingleObject,
} from "../../app/reducers/MediaLibrary/mediaLibrarySlice";
import FormikSelectGroup from "../../components/formik/FormikSelectGroup";
import { DEPARTMENTS, DESIGNATIONS, USER_ROLE } from "../../utils/dropdownOptions";
import FormikTextareaGroup from "../../components/formik/FormikTextareaGroup";

const AddUser = () => {
  const { loading } = useSelector(getUsers);
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const formik = useFormik({
    initialValues: {
      firstName: "",
      lastName: "",
      employeeId: "",
      username: "91",
      alternatePhone: "91",
      notes: "",
      username: "91",
      password: "",
      designation: "",
      department: "",
      dateOfBirth: "",
      joiningDate: "",
      active: true,
      identityProof: [],
      role: "user"
    },
    validationSchema: object({
      firstName: string().required(),
      lastName: string().required(),
      employeeId: string().required(),
      username: string().required(),
      password: string().required(),
      designation: string().required(),
      department: string().required(),
      identityProof: array().min(1).required(),

    }),
    onSubmit: async (values) => {
      console.log(values);
      const location = `black_ink/users/${values.username}/`;
      await dispatch(createUser({ ...values, location }));
      navigate("/users/viewUsers");
    },
  });
  return (
    <PageWithCard>
      {loading ? (
        <ClipLoader />
      ) : (
        <form onSubmit={formik.handleSubmit} className="flex flex-col gap-4">
          <FormikInputGroup
            name="firstName"
            label="First Name"
            formik={formik}
            required
          />
          <FormikInputGroup
            name="lastName"
            label="Last Name"
            formik={formik}
            required
          />
          <FormikInputGroup
            name="employeeId"
            label="Employee Id"
            formik={formik}
            required
          />
          <FormikPhoneInputGroup
            name="username"
            label="Phone Number"
            formik={formik}
            required
          />
          <FormikPhoneInputGroup
            name="alternatePhone"
            label="Alternate Phone Number"
            formik={formik}
          />
          <FormikInputGroup
            name="password"
            type="password"
            label="Password"
            formik={formik}
            required
          />
          <FormikSelectGroup
            name="designation"
            label="Designation"
            formik={formik}
            options={DESIGNATIONS}
          />
          <FormikSelectGroup
            name="department"
            label="Department"
            formik={formik}
            options={DEPARTMENTS}
          />
          <FormikInputDateGroup
            name="dateOfBirth"
            label="Date of Birth"
            formik={formik}
          />
          <FormikInputDateGroup
            name="joiningDate"
            label="Joining Date"
            formik={formik}
          />
          <FormikSelectGroup
            formik={formik}
            label="Role"
            name={'role'}
            options={USER_ROLE}

          />
          <FormikCheckbox name="active" label="Active" formik={formik} />
          <FormikTextareaGroup name="notes" label="Notes" formik={formik} />
          <FormikProvider value={formik}>
            <FieldArray
              name="identityProof"
              render={(arrayHelpers) => {
                return (
                  <div className="flex flex-col gap-2 w-full">
                    {formik.values.identityProof.map((ele, i) => (
                      <div
                        className="grid grid-cols-3 gap-2 items-center"
                        key={i}
                      >
                        <FormikInputGroup
                          name={`identityProof.${i}.name`}
                          formik={formik}
                          required
                          label="Document Name"
                        />
                        <FormikDirectFileUpload
                          name={`identityProof.${i}.file`}
                          formik={formik}
                          required
                          label="File"
                          location={`black_ink/users/${formik.values.username}/`}
                        />
                        <div className="flex items-end h-full">
                          <DangerButton
                            onClick={async () => {
                              console.log(formik.values.identityProof[i].file);
                              if (formik.values.identityProof?.[i]?.file) {
                                await dispatch(
                                  deleteSingleObject({
                                    keys: [formik.values.identityProof[i].file],
                                  })
                                );
                              }
                              arrayHelpers.remove(i);
                            }}
                          >
                            Remove
                          </DangerButton>
                        </div>
                      </div>
                    ))}
                    <div>
                      <SecondaryButton
                        onClick={() => {
                          if (formik.values.username.length > 3) {
                            arrayHelpers.push({
                              name: "",
                              file: "",
                            });
                          } else {
                            alert("need phone to add identity proof");
                          }
                        }}
                      >
                        Add Identity Proof
                      </SecondaryButton>
                    </div>
                  </div>
                );
              }}
            />
          </FormikProvider>
          <div>
            <PrimaryButton type="submit">Submit</PrimaryButton>
          </div>
        </form>
      )}
    </PageWithCard>
  );
};

export default AddUser;
