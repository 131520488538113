import React from "react";
import DatePicker from "react-datepicker";
import _ from "lodash";
import moment from "moment";
const FormikInputDateGroup = ({
  formik,
  label = "",
  required = false,
  name,
  ...props
}) => {
  return (
    <div
      onClick={(e) => {
        e.stopPropagation();
      }}
      className="w-full"
    >
      <label className="block text-sm font-medium mb-1" htmlFor={name}>
        {label} {required && <span className="text-rose-500">*</span>}
      </label>
      <DatePicker
        name={name}
        selected={moment(_.at(formik.values, name)[0]).valueOf()}
        onBlur={formik.handleBlur}
        onChange={(selectedDate) => {
          formik.setFieldValue(name, new Date(selectedDate).getTime());
        }}
        {...props}
        className="w-full form-input"
        dateFormat="dd/MM/yyyy"
        autoComplete="off"
        dropdownMode="select"
      />
      {formik?.errors?.[name]?.value && formik?.touched?.[name] ? (
        <p className="text-xs text-red-500">{formik.errors[name].value}</p>
      ) : null}
    </div>
  );
};

export default FormikInputDateGroup;
