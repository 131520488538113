import React, { useEffect } from "react";
import { useSelector } from "react-redux";
import { Outlet } from "react-router";
import { useLocation } from "react-router-dom";
import { getProjects } from "../../app/reducers/Project/projectSlice";
import { getGlobalLoading } from "../../app/reducers/GlobalLoading/globalLoadingSlice";

const Layout = () => {
  const location = useLocation();
  const { globalLoading } = useSelector(getGlobalLoading);

  useEffect(() => {
    let scrollVal;
    let listener = null;
    const ele = document.querySelector("#overflow");
    if (ele) {
      const scroll = sessionStorage.getItem(window.location.pathname);
      setTimeout(() => {
        ele.scroll(0, scroll);
        listener = ele.addEventListener("scroll", (event) => {
          scrollVal = ele.scrollTop;

          sessionStorage.setItem(
            window.location.pathname,
            scrollVal.toString()
          );
        });
      }, 700);
    }

    return () => {
      console.log("removed location event listener");
      if (ele && listener) {
        ele.removeEventListener("scroll", listener);
      }
    };
  }, [location]);
  return (
    <>
      <Outlet />
    </>
  );
};

export default Layout;
