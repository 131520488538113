import React from "react";

const SidebarTopText = ({ text, sidebarOpen }) => {
  return (
    <h3 className="text-xs uppercase text-slate-500 font-semibold pl-3 mt-5">
      <span
        className="hidden lg:block lg:sidebar-expanded:hidden text-center w-6"
        aria-hidden="true"
      >
        •••
      </span>
      <span
        className={`sidebar-expanded:block ${sidebarOpen ? "block" : "hidden"}`}
      >
        {text}
      </span>
    </h3>
  );
};

export default SidebarTopText;
