import React, { useCallback, useEffect, useMemo, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import PrimaryButton from "../components/infrastructure/Buttons/PrimaryButton";
import PaginationClassic from "../components/infrastructure/pagination/PaginationClassic";
import TableWithHeadingAndSearch from "../components/Table/TableWithHeadingAndSearch";
import {
  fetchSubmissions,
  getSubmissions,
} from "../app/reducers/Submission/submissionSlice";
import { fetchLeaves, getLeaves } from "../app/reducers/Leaves/leaveSlice";
import moment from "moment";
import { authAxiosInstance, axiosInstance } from "../utils/axiosConfig";
import { saveAs } from "file-saver";
import QueryString from "qs";
import UpdateLeaveStatusModal from "./Submission/UpdateLeaveStatusModal";

const Dashboard = () => {
  const [page, setPage] = useState(1);
  const [leavePage, setLeavePage] = useState(1);
  const [modalIsOpen, setModalIsOpen] = React.useState(false);
  const [leaveStatusId, setLeaveStatusId] = useState(null);
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const { submission, loading } = useSelector(getSubmissions);
  const { leave } = useSelector(getLeaves);

  const handleStatusAction = (id) => {
    setModalIsOpen(true);
    setLeaveStatusId(id);
  };

  useEffect(() => {
    dispatch(fetchLeaves({ populate: true }));
  }, [leavePage]);

  useEffect(() => {
    dispatch(
      fetchSubmissions({
        createdAt: {
          $gte: moment().startOf("isoweek").toDate().getTime(),
          $lt: moment().endOf("isoweek").toDate().getTime(),
        },
        populate: true,
      })
    );
  }, [page]);

  const debouncedSearch = useCallback(
    _.debounce((search) => {
      dispatch(fetchSubmissions({ search: search }));
    }, 300),
    []
  );

  const cols = [
    {
      Header: "Submission Id",
      accessor: "_id",
    },
    {
      Header: "Name",
      accessor: "projectName",
    },
    {
      Header: "Submitted By",
      Cell: ({ row }) => {
        return (
          row.original.submittedBy?.firstName +
          " " +
          row.original.submittedBy?.lastName
        );
      },
    },
    {
      Header: "Submitted Date",
      Cell: ({ row }) => {
        return moment(row.original.createdAt).format("DD-MM-YYYY");
      },
    },
    {
      Header: "Client First Name",
      accessor: "clientFirstName",
    },
    {
      Header: "Client Last Name",
      accessor: "clientLastName",
    },
    {
      Header: "Client Email",
      accessor: "clientEmail",
    },
    {
      Header: "Area of Checking",
      accessor: "areaOfChecking",
    },
    {
      Header: "Sub Project",
      accessor: "subProject",
    },
    {
      Header: "Action",
      Cell: ({ row }) => {
        return (
          <div className="flex gap-3 items-center">
            <PrimaryButton
              onClick={async (e) => {
                try {
                  const string = QueryString.stringify({
                    id: row.original?._id,
                  });
                  const response = await axiosInstance.get(
                    `/submission/pdf?${string}`,
                    { responseType: "blob" }
                  );
                  saveAs(response.data);
                } catch (error) {
                  console.log(error);
                }
              }}
            >
              Download Pdf
            </PrimaryButton>
          </div>
        );
      },
    },
  ];

  const leaveCols = [
    {
      Header: "Submission Id",
      accessor: "_id",
    },
    {
      Header: "Employee Id",
      accessor: "employeeId",
    },
    {
      Header: "Designation",
      accessor: "designation",
    },
    {
      Header: "Submitted By",
      Cell: ({ row }) => {
        return (
          row.original.submittedBy?.firstName +
          " " +
          row.original.submittedBy?.lastName
        );
      },
    },
    {
      Header: "Submitted Date",
      Cell: ({ row }) => {
        return moment(row.original.createdAt).format("DD-MM-YYYY");
      },
    },
    {
      Header: "Type Of Leave",
      accessor: "typeOfLeave",
    },
    {
      Header: "Status",
      accessor: "status",
    },
    {
      Header: "Reason For Leave",
      accessor: "leaveReason",
    },
    {
      Header: "Leave Dates",
      Cell: ({ row }) => {
        return (
          row.original?.selectedDates?.length > 0 &&
          row.original.selectedDates.map((d, i) => (
            <li key={i}>{moment(d).format("DD/MM/YYYY")}</li>
          ))
        );
      },
    },
    {
      Header: "Half Day",
      Cell: ({ row }) => {
        return row.original?.halfDayDate ? (
          <div>{moment(row.original?.halfDayDate).format("DD/MM/YYYY")}</div>
        ) : (
          "---------"
        );
      },
    },
    {
      Header: "Half Day Type",
      accessor: "halfDayType",
    },
    {
      Header: "Action",
      Cell: ({ row }) => {
        return (
          <>
            <PrimaryButton
              onClick={(e) => {
                e.stopPropagation();
                handleStatusAction(row?.original?._id);
              }}
            >
              Change
            </PrimaryButton>
          </>
        );
      },
    },
  ];

  const data = useMemo(
    () => (submission?.docs ? submission.docs : []),
    [submission]
  );
  const leaveData = useMemo(() => (leave?.docs ? leave.docs : []), [leave]);

  return (
    <>
      <UpdateLeaveStatusModal
        updateLeaveStatusModalOpen={modalIsOpen}
        setUpdateLeaveStatusModalOpen={setModalIsOpen}
        leaveId={leaveStatusId}
      />
      <div className="px-4 sm:px-6 lg:px-8 py-8 w-full max-w-9xl mx-auto">
        {/* Page header */}
        <div className="mb-8">
          {/* Title */}
          <h1 className="text-2xl md:text-3xl text-slate-800 font-bold">
            Dashboard
          </h1>
        </div>
        <div className="p-4">
          <TableWithHeadingAndSearch
            loading={loading}
            heading="Submissions"
            data={data}
            searchFunction={debouncedSearch}
            columns={cols}
          />
          <PaginationClassic setPage={setPage} paginationDetails={submission} />
        </div>
        <div className="mt-2">
          <TableWithHeadingAndSearch
            loading={loading}
            heading="Leaves"
            data={leaveData}
            searchFunction={debouncedSearch}
            columns={leaveCols}
          />
          <PaginationClassic setPage={setLeavePage} paginationDetails={leave} />
        </div>
      </div>
    </>
  );
};

export default Dashboard;
