import moment from "moment";
import React, { useCallback, useEffect, useMemo, useState } from "react";
import { Trash } from "react-feather";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import { getMediaLibrary } from "../../app/reducers/MediaLibrary/mediaLibrarySlice";
import {
  deleteProject,
  fetchProjects,
  getProjects,
} from "../../app/reducers/Project/projectSlice";
import PrimaryButton from "../../components/infrastructure/Buttons/PrimaryButton";
import PaginationClassic from "../../components/infrastructure/pagination/PaginationClassic";
import TableWithHeadingAndGlobalSearch from "../../components/Table/TableWithHeadingAndGlobalSearch";
import PageWithCard from "../../components/infrastructure/PageWithCard";

const ViewAreaOfCheck = () => {
  const navigate = useNavigate();
  const { projectName } = useParams();
  const dispatch = useDispatch();

  const { project, loading } = useSelector(getProjects);

  useEffect(() => {
    dispatch(fetchProjects({ name: projectName }));
  }, []);

  const data = useMemo(
    () => (project?.docs ? project.docs[0].areaCheck : []),
    [project]
  );

  return (
    <PageWithCard heading="Area Of Checking">
      <div className="p-4">
        <div style={{ display: "flex", gap: "2rem", flexWrap: "wrap" }}>
          {data && data.length > 0
            ? data.map((d, i) => (
                <div
                  className="max-w-sm rounded border overflow-hidden shadow-lg text-center mb-3 cursor-pointer"
                  style={{ width: "250px" }}
                  onClick={(e) => {
                    navigate(`${d}`);
                  }}
                  key={i}
                >
                  <div className="px-3 pt-3 mb-5">
                    <p className="text-xl">{d}</p>
                  </div>
                </div>
              ))
            : "No Data Found"}
        </div>
      </div>
    </PageWithCard>
  );
};

export default ViewAreaOfCheck;
