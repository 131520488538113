import { createSlice } from "@reduxjs/toolkit";
import { generateStateSingle } from "../../generators/generateState";
import {
  createData,
  deleteData,
  editData,
  fetchData,
  fetchEditData,
} from "../../generators/generateThunks";

const SLICE_NAME = "submission";

export const fetchSubmissions = fetchData.generateThunk(SLICE_NAME, SLICE_NAME);
export const fetchProject = fetchEditData.generateThunk(SLICE_NAME, SLICE_NAME);
export const createProject = createData .generateThunk(SLICE_NAME,SLICE_NAME);
export const deleteProject = deleteData.generateThunk(SLICE_NAME, SLICE_NAME);
export const editProject = editData.generateThunk(SLICE_NAME, SLICE_NAME);

const submissionReducer = createSlice({
  initialState: generateStateSingle(SLICE_NAME),
  name: SLICE_NAME,
  extraReducers: {
    ...fetchData.generateExtraReducers(fetchSubmissions, SLICE_NAME),
    ...fetchEditData.generateExtraReducers(fetchProject, SLICE_NAME),
    ...createData.generateExtraReducers(createProject, SLICE_NAME),
    ...deleteData.generateExtraReducers(deleteProject, SLICE_NAME),
    ...editData.generateExtraReducers(editProject, SLICE_NAME),
  },
});

export const getSubmissions = (state) => state.submissions;

export default submissionReducer.reducer;
