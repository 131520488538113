import { configureStore } from "@reduxjs/toolkit";
import authReducer from "./reducers/Auth/authSlice";
import mediaLibrarySlice from "./reducers/MediaLibrary/mediaLibrarySlice";
import emailSlice from "./reducers/Emails/emailSlice";
import usersSlice from "./reducers/Users/usersSlice";
import projectSlice from "./reducers/Project/projectSlice";
import submissionSlice from "./reducers/Submission/submissionSlice";
import leaveSlice from "./reducers/Leaves/leaveSlice";
import loadingSlice from "./reducers/GlobalLoading/globalLoadingSlice";

export const store = configureStore({
  reducer: {
    auth: authReducer,
    mediaLibrary: mediaLibrarySlice,
    emails: emailSlice,
    users: usersSlice,
    projects: projectSlice,
    submissions: submissionSlice,
    leaves: leaveSlice,
    globalLoading: loadingSlice,
  },
});
