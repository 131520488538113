import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import ReactSelect from "react-select/async";
import {
  fetchUser,
  fetchUsers,
  getUsers,
} from "../../app/reducers/Users/usersSlice";
import PageWithCard from "../../components/infrastructure/PageWithCard";

const EditUser = () => {
  const dispatch = useDispatch();
  const { users } = useSelector(getUsers);
  const navigate = useNavigate();
  return (
    <PageWithCard>
      <ReactSelect
        loadOptions={async (value) => {
          await dispatch(fetchUsers({ search: value }));
          return users?.docs
            ? users.docs.map((ele) => ({
              label: ` ${ele.employeeId}-${ele.firstName} ${ele.lastName}`,
              value: ele._id,
            }))
            : [];
        }}
        onChange={(selectedOption) => {
          navigate(`/users/editUser/${selectedOption.value}`);
        }}
      />
    </PageWithCard>
  );
};

export default EditUser;
