import React from "react";
import { useRef } from "react";
import { bytesToMegaBytes } from "../../utils/Utils";

const FormikFileInput = ({ name, formik, label = "", required, ...props }) => {
  const ref = useRef();
  return (
    <div>
      <label className="block text-sm font-medium mb-1" htmlFor={name}>
        {label} {required && <span className="text-rose-500">*</span>}
      </label>

      <input
        ref={ref}
        type="file"
        name={name}
        onChange={(e) => {
          console.log(e.currentTarget.files[0].size);
          console.log(bytesToMegaBytes(e.currentTarget.files[0].size));
          if (bytesToMegaBytes(e.currentTarget.files[0].size) < 30) {
            formik.setFieldValue(name, e.currentTarget.files[0]);
          } else {
            formik.setFieldValue(name, null);
            ref.current.value = "";
            alert("file size too large");
          }
        }}
        {...props}
        className="w-full form-input"
        onBlur={formik.handleBlur}
      />
      {formik.errors[name] && formik.touched[name] ? (
        <p className="text-xs text-red-500">{formik.errors[name]}</p>
      ) : null}
    </div>
  );
};

export default FormikFileInput;
