import React from "react";

const FormikCheckbox = ({ formik, name, label }) => {
  //   console.log(formik?.values?.[name]?.includes(val));
  return (
    <div className="flex items-center gap-2">
      <input
        type="checkbox"
        onChange={formik.handleChange}
        name={name}
        checked={formik?.values?.[name]}
      />
      <label>{label}</label>
    </div>
  );
};

export default FormikCheckbox;
