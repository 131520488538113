import React from "react";
import { useCallback } from "react";
import { useMemo } from "react";
import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { ClipLoader } from "react-spinners";
import PageWithCard from "../../components/infrastructure/PageWithCard";
import TableWithHeadingAndGlobalSearch from "../../components/Table/TableWithHeadingAndGlobalSearch";
import _ from "lodash";
import {
  deleteEmail,
  fetchEmails,
  getEmails,
  setEditId,
} from "../../app/reducers/Emails/emailSlice";
import { Edit2, Trash } from "react-feather";
import { useNavigate } from "react-router-dom";

const ViewEmails = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  useEffect(() => {
    dispatch(fetchEmails());
  }, []);
  const columns = [
    {
      Header: "SRNO",
      Cell: (prop) => {
        const { data, row } = prop;
        return row.index + 1;
      },
    },
    {
      Header: "Name",
      accessor: "name",
    },
    {
      Header: "Action",
      Cell: (prop) => {
        const { data, row } = prop;
        return (
          <div
            className="flex items-center gap-2"
            onClick={(e) => {
              e.stopPropagation();
            }}
          >
            <Edit2
              color="blue"
              onClick={() => {
                navigate("/emails/editEmails", {
                  state: {
                    design: row.original.design,
                    name: row.original.name,
                    _id: row.original._id,
                  },
                });
              }}
            />
            <Trash
              color="red"
              onClick={async () => {
                await dispatch(deleteEmail({ id: row.original._id }));
                dispatch(fetchEmails());
              }}
            />
          </div>
        );
      },
    },
  ];

  const { emails, loading } = useSelector(getEmails);
  const data = useMemo(() => (emails?.docs ? emails.docs : []), [emails]);
  const colsMemo = useMemo(() => columns, [columns]);
  const debouncedSearch = useCallback(
    _.debounce((search) => {
      console.log("searching");
      dispatch(fetchEmails({ search: search }));
    }, 300),
    []
  );
  return (
    <PageWithCard heading="Emails">
      <TableWithHeadingAndGlobalSearch
        heading="Emails"
        data={data}
        columns={colsMemo}
        loading={loading}
        searchFunction={(value) => {
          debouncedSearch(value);
        }}
      />
    </PageWithCard>
  );
};

export default ViewEmails;
