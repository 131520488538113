import moment from "moment";
import React, {
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import { Search, Trash } from "react-feather";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { getMediaLibrary } from "../../app/reducers/MediaLibrary/mediaLibrarySlice";
import {
  deleteProject,
  fetchProjects,
  getProjects,
} from "../../app/reducers/Project/projectSlice";
import PrimaryButton from "../../components/infrastructure/Buttons/PrimaryButton";
import PaginationClassic from "../../components/infrastructure/pagination/PaginationClassic";
import PageWithCard from "../../components/infrastructure/PageWithCard";
import _ from "lodash";

const ViewProjects = () => {
  const [page, setPage] = useState(1);
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const { project, loading } = useSelector(getProjects);

  useEffect(() => {
    dispatch(fetchProjects());
  }, [page]);

  const data = useMemo(() => (project?.docs ? project.docs : []), [project]);

  const handleDebouncedSearch = useCallback(
    _.debounce((value) => {
      dispatch(fetchProjects({ search: value }));
    }, 300),
    []
  );

  return (
    <PageWithCard heading="Projects">
      <div className="  flex border  w-[78%] p-2 rounded-md gap-2 shadow-sm m-4">
        <Search size={"24px"} color="gray" />
        <input
          className="text italic text-lg"
          placeholder="Search Projects..."
          onChange={(e) => handleDebouncedSearch(e.target.value)}
        />
      </div>
      <div className="p-4">
        <div style={{ display: "flex", gap: "2rem", flexWrap: "wrap" }}>
          {data && data.length > 0
            ? data.map((d, i) => (
                <div
                  className="max-w-sm rounded border overflow-hidden shadow-lg text-center mb-3 cursor-pointer"
                  style={{ width: "250px" }}
                  onClick={(e) => {
                    navigate(`${d.name}`);
                  }}
                  key={i}
                >
                  <div className="px-3 pt-3 mb-5">
                    <p className="text-xl">{d.name}</p>
                  </div>
                </div>
              ))
            : "No Data Found"}
        </div>
      </div>
    </PageWithCard>
  );
};

export default ViewProjects;
