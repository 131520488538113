import React, { Component } from 'react'
import { Map, Marker, GoogleApiWrapper } from 'google-maps-react'

const containerStyle = {
	width: '45%',
	height: '45%',
}

export class MapContainer extends Component {
	render() {
		return (
			<Map initialCenter={this.props.position} style={containerStyle} google={this.props.google} zoom={14}>
				<Marker position={this.props.position} name={'Current location'} />
			</Map>
		)
	}
}

export default GoogleApiWrapper({
	apiKey: '',
})(MapContainer)
