import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import ReactSelect from "react-select/async";
import { getProjects,fetchProjects } from "../../app/reducers/Project/projectSlice";
import PageWithCard from "../../components/infrastructure/PageWithCard";

const EditProject = () => {
  const dispatch = useDispatch();
  const { project } = useSelector(getProjects);
  const navigate = useNavigate();
  return (
    <PageWithCard>
      <ReactSelect
        loadOptions={async (value) => {
          await dispatch(fetchProjects({ search: value }));
          return project?.docs
            ? project.docs.map((ele) => ({
                label: `${ele.name}`,
                value: ele._id,
              }))
            : [];
        }}
        onChange={(selectedOption) => {
          navigate(`/project/editProject/${selectedOption.value}`);
        }}
      />
    </PageWithCard>
  );
};

export default EditProject;
