import React, { useEffect } from "react";
import { Routes, Route, useLocation } from "react-router-dom";
import Layout from "./components/infrastructure/Layout";
import PersistLogin from "./components/infrastructure/PersistLogin";

import "./css/style.scss";
import Dashboard from "./pages/Dashboard";
import Login from "./pages/Auth/Login";
import PageNotFound from "./pages/PageNotFound";
import Logout from "./pages/Auth/Logout";

import RouteWithHeaderAndSidebar from "./components/infrastructure/RouteWithHeaderAndSidebar";
import "react-datepicker/dist/react-datepicker.css";
import MediaLibrary from "./pages/MediaLibrary/MediaLibrary";
import AddEmails from "./pages/Emails/AddEmails";
import ViewEmails from "./pages/Emails/ViewEmails";
import EditEmails from "./pages/Emails/EditEmails";
import AddUser from "./pages/Users/AddUser";
import ViewUsers from "./pages/Users/ViewUsers";
import MediaLibraryAssets from "./pages/MediaLibrary/MediaLibraryAssets";
import EditUser from "./pages/Users/EditUser";
import EditUserPage from "./pages/Users/EditUserPage";
import AddProject from "./pages/Project/AddProject";
import ViewProject from "./pages/Project/ViewProject";
import EditProductPage from "./pages/Project/EditProjectPage";
import EditProject from "./pages/Project/EditProject";
import ViewSubmission from "./pages/Submission/ViewSubmission";
import ViewSubProject from "./pages/Submission/ViewSubProject";
import ViewProjects from "./pages/Submission/ViewProjects";
import ViewAreaOfCheck from "./pages/Submission/ViewAreaOfCheck";
import MyDocument from "./pages/MyDocument";
import ViewLeaves from "./pages/Submission/ViewLeaves";
import { useSelector } from "react-redux";
import { getAuth } from "./app/reducers/Auth/authSlice";

function App() {
  const location = useLocation();
  const { user } = useSelector(getAuth);

  useEffect(() => {
    document.querySelector("html").style.scrollBehavior = "auto";
    window.scroll({ top: 0 });
    document.querySelector("html").style.scrollBehavior = "";
  }, [location.pathname]); // triggered on route change

  return (
    <>
      <Routes>
        <Route path="/" element={<Layout />}>
          <Route element={<PersistLogin />}>
            <Route exact path="/logout" element={<Logout />} />
            <Route path="*" element={<PageNotFound />} />
            <Route path="/" element={<RouteWithHeaderAndSidebar />}>
              <Route exact path="/" element={<Dashboard />} />
              <Route exact path="/emails/addEmails" element={<AddEmails />} />
              <Route exact path="/emails/viewEmails" element={<ViewEmails />} />
              <Route exact path="/emails/editEmails" element={<EditEmails />} />
              <Route path="/users">
                <Route exact path="addUser" element={<AddUser />} />
                <Route exact path="viewUsers" element={<ViewUsers />} />
                <Route exact path="editUser">
                  <Route index element={<EditUser />} />
                  <Route path=":id" element={<EditUserPage />} />
                </Route>
              </Route>
              <Route path="/project">
                <Route exact path="addProject" element={<AddProject />} />
                <Route exact path="viewProject" element={<ViewProject />} />
                <Route exact path="editProject">
                  <Route index element={<EditProject />} />
                  <Route path=":id" element={<EditProductPage />} />
                </Route>
              </Route>
              <Route path="/submission">
                <Route exact path="project">
                  <Route index element={<ViewProjects />} />
                  <Route path=":projectName">
                    <Route index element={<ViewSubProject />} />
                    <Route path=":subProject">
                      <Route index element={<ViewAreaOfCheck />} />
                      <Route path=":areaCheck" element={<ViewSubmission />} />
                    </Route>
                  </Route>
                </Route>
              </Route>

              <Route path="/mediaLibrary">
                <Route index element={<MediaLibrary />} />
                <Route path="*" element={<MediaLibrary />} />
              </Route>
              <Route path="/document" element={<MyDocument />} />
              <Route path="/viewLeaves" element={<ViewLeaves />} />
            </Route>
          </Route>
          <Route exact path="/login" element={<Login />} />
        </Route>
      </Routes>
    </>
  );
}

export default App;
