import React, { useCallback, useEffect, useMemo, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import PrimaryButton from "../../components/infrastructure/Buttons/PrimaryButton";
import PaginationClassic from "../../components/infrastructure/pagination/PaginationClassic";
import TableWithHeadingAndSearch from "../../components/Table/TableWithHeadingAndSearch";
import { fetchLeaves, getLeaves } from "../../app/reducers/Leaves/leaveSlice";
import moment from "moment";
import { PDFDownloadLink } from "@react-pdf/renderer";
import { authAxiosInstance } from "../../utils/axiosConfig";
import MyDocument from "../MyDocument";
import { useFormik } from "formik";
import FormikMultiSelect from "../../components/formik/FormikMultiSelect";
import FormikInputDateGroup from "../../components/formik/FormikInputDateGroup";
import { LEAVE_STATUS } from "../../utils/dropdownOptions";
import UpdateLeaveStatusModal from "./UpdateLeaveStatusModal";
import { fetchUsers, getUsers } from "../../app/reducers/Users/usersSlice";
import { generateOptions } from "../../utils/Utils";

const ViewLeaves = () => {
  const [leavePage, setLeavePage] = useState(1);
  const { users } = useSelector(getUsers);
  const dispatch = useDispatch();
  const [modalIsOpen, setModalIsOpen] = React.useState(false);
  const [leaveStatusId, setLeaveStatusId] = useState(null);
  const { leave, loading } = useSelector(getLeaves);
  const [selectedRows, setSelectedRows] = useState([]);

  const formik = useFormik({
    initialValues: {
      status: [],
      employee: [],
      from: moment(new Date()).subtract(1, "days").valueOf(),
      to: moment(new Date()).add(1, "days").valueOf(),
    },
    onSubmit: (values) => {
      dispatch(
        fetchLeaves({
          populate: true,
          status: { $in: values.status },
          "submittedBy._id": { $in: values.employee },
          createdAt: {
            $gte: formik.values.from,
            $lte: formik.values.to,
          },
        })
      );
    },
  });

  useEffect(() => {
    dispatch(
      fetchLeaves({
        populate: true,
        // status: { $in: formik.values.status },
        // "submittedBy._id": { $in: formik.values.employee },
        // createdAt: {
        //   $gte: formik.values.from,
        //   $lte: formik.values.to,
        // },
      })
    );
    dispatch(fetchUsers());
  }, [leavePage]);

  const handleStatusAction = (id) => {
    setModalIsOpen(true);
    setLeaveStatusId(id);
  };

  const debouncedSearch = useCallback(
    _.debounce((search) => {
      dispatch(
        fetchLeaves({
          search: search,
          populate: true,
          status: { $in: formik.values.status },
          employeeId: { $in: formik.values.employee },
        })
      );
    }, 300),
    []
  );

  const leaveCols = [
    {
      Header: "Select",
      Cell: ({ row }) => {
        return (
          <input
            type="checkbox"
            onChange={(e) => {
              // console.log(e.target.value);
              // console.log(e.target.checked);
              if (e.target.checked) {
                setSelectedRows([...selectedRows, row.original]);
              } else {
                let filtered = selectedRows.filter(
                  (ele) => ele._id !== row.original._id
                );
                setSelectedRows(filtered);
              }
            }}
            checked={selectedRows.find(
              (ele) => ele?._id === row?.original?._id
            )}
          />
        );
      },
    },
    {
      Header: "Submitted By",
      Cell: ({ row }) => {
        return (
          row.original.submittedBy?.firstName +
          " " +
          row.original.submittedBy?.lastName
        );
      },
    },
    {
      Header: "Emp Id",
      accessor: "employeeId",
    },
    {
      Header: "Designation",
      accessor: "designation",
    },

    {
      Header: "Submitted Date",
      Cell: ({ row }) => {
        return moment(row.original.createdAt).format("DD-MM-YYYY");
      },
    },
    {
      Header: "Type Of Leave",
      accessor: "typeOfLeave",
    },
    {
      Header: "No. Of Days",
      accessor: "NoOfDays",
    },
    {
      Header: "Reason For Leave",
      accessor: "leaveReason",
    },
    {
      Header: "Status",
      accessor: "status",
    },
    {
      Header: "Leave Dates",
      Cell: ({ row }) => {
        return (
          row.original?.selectedDates?.length > 0 && (
            <div className="flex flex-col">
              {row.original.selectedDates.map((d, i) => (
                <span key={i}>{moment(d).format("DD/MM/YYYY")}</span>
              ))}
            </div>
          )
        );
      },
    },
    {
      Header: "Half Day",
      Cell: ({ row }) => {
        return row.original?.halfDayDate ? (
          <div>{moment(row.original?.halfDayDate).format("DD/MM/YYYY")}</div>
        ) : (
          "---------"
        );
      },
    },
    // {
    //   Header: "Half Day Type",
    //   accessor: "halfDayType",
    // },
    {
      Header: "Action",
      Cell: ({ row }) => {
        return (
          <>
            <PrimaryButton
              onClick={(e) => {
                e.stopPropagation();
                handleStatusAction(row?.original?._id);
              }}
            >
              Change
            </PrimaryButton>
          </>
        );
      },
    },
  ];

  const leaveData = useMemo(() => (leave?.docs ? leave.docs : []), [leave]);

  console.log("leaveData", leaveData)
  return (
    <>
      <UpdateLeaveStatusModal
        updateLeaveStatusModalOpen={modalIsOpen}
        setUpdateLeaveStatusModalOpen={setModalIsOpen}
        leaveId={leaveStatusId}
      />
      <div className="px-4 sm:px-6 lg:px-8 py-8 w-full max-w-9xl mx-auto">
        <div className="mt-2 flex flex-col gap-4">
          <form onSubmit={formik.handleSubmit} className="flex flex-col gap-2">
            <FormikMultiSelect
              label="Status"
              options={LEAVE_STATUS}
              formik={formik}
              name="status"
            />
            <FormikMultiSelect
              label="Employee"
              options={generateOptions({
                array: users?.docs ? users?.docs : [],
                valueField: "_id",
                labelField: "firstName",
              })}
              formik={formik}
              name="employee"
            />
            <FormikInputDateGroup
              label="Date From"
              name="from"
              formik={formik}
            />
            <FormikInputDateGroup label="Date To" name="to" formik={formik} />
            <div className="flex gap-4">
              <PrimaryButton type="submit">Submit</PrimaryButton>
              <PrimaryButton>
                <PDFDownloadLink
                  document={<MyDocument leaveData={selectedRows} />}
                  fileName="BlackInk.pdf"
                >
                  Print Data ({selectedRows.length})
                </PDFDownloadLink>
              </PrimaryButton>
            </div>
          </form>
          <TableWithHeadingAndSearch
            loading={loading}
            heading="Leaves"
            data={leaveData}
            searchFunction={debouncedSearch}
            columns={leaveCols}
          />
          <PaginationClassic setPage={setLeavePage} paginationDetails={leave} />
        </div>
      </div>
    </>
  );
};

export default ViewLeaves;
