import { createSlice } from "@reduxjs/toolkit";
import { generateSetEditId } from "../../generators/generateReducers";
import { generateStateSingle } from "../../generators/generateState";
import {
  createData,
  deleteData,
  editData,
  fetchData,
  fetchEditData,
} from "../../generators/generateThunks";
const SLICE_NAME = "emails";

export const fetchEmails = fetchData.generateThunk(SLICE_NAME, SLICE_NAME);
export const editEmail = editData.generateThunk(SLICE_NAME, SLICE_NAME);
export const fetchEmail = fetchEditData.generateThunk(SLICE_NAME, SLICE_NAME);
export const deleteEmail = deleteData.generateThunk(SLICE_NAME, SLICE_NAME);
export const createEmail = createData.generateThunk(SLICE_NAME, SLICE_NAME);

const emailSlice = createSlice({
  name: SLICE_NAME,
  initialState: generateStateSingle(SLICE_NAME),
  reducers: {
    setEditId: generateSetEditId(),
  },
  extraReducers: {
    ...fetchData.generateExtraReducers(fetchEmails, SLICE_NAME),
    ...createData.generateExtraReducers(createEmail, SLICE_NAME),
    ...deleteData.generateExtraReducers(deleteEmail, SLICE_NAME),
    ...fetchEditData.generateExtraReducers(fetchEmail, SLICE_NAME),
    ...editData.generateExtraReducers(editEmail, SLICE_NAME),
  },
});

export const getEmails = (state) => state.emails;
export const { setEditId } = emailSlice.actions;
export default emailSlice.reducer;
