import moment from "moment";
import React, { useCallback, useEffect, useMemo, useState } from "react";
import { Trash } from "react-feather";
import { useDispatch, useSelector } from "react-redux";
import { getMediaLibrary } from "../../app/reducers/MediaLibrary/mediaLibrarySlice";
import {
  deleteUser,
  fetchUsers,
  getUsers,
} from "../../app/reducers/Users/usersSlice";
import PageWithCard from "../../components/infrastructure/PageWithCard";
import PaginationClassic from "../../components/infrastructure/pagination/PaginationClassic";
import TableWithHeadingAndGlobalSearch from "../../components/Table/TableWithHeadingAndGlobalSearch";

const ViewUsers = () => {
  const { users, loading } = useSelector(getUsers);
  const { baseUrl } = useSelector(getMediaLibrary);
  const dispatch = useDispatch();
  const debouncedSearch = useCallback(
    _.debounce((search) => {
      console.log("searching");
      dispatch(fetchUsers({ search: search, populate: true }));
    }, 300),
    []
  );
  const [page, setPage] = useState(1);

  const cols = [
    {
      Header: "Name",
      Cell: ({ row }) => {
        return (
          <span>
            {row.original.firstName} {row.original.lastName}
          </span>
        );
      },
    },
    {
      Header: "Employee Id",
      accessor: "employeeId",
    },
    {
      Header: "Phone",
      accessor: "username",
      Cell: ({ row }) => (
        <div className="flex flex-col gap-2">
          {row.original.username && <span>{row.original.username}</span>}
          {row.original.alternatePhone && (
            <span>{row.original.alternatePhone}</span>
          )}
        </div>
      ),
    },
    {
      Header: "Designation",
      accessor: "designation",
    },
    {
      Header: "Department",
      accessor: "department",
    },
    {
      Header: "Date of Birth",
      Cell: ({ row }) => {
        return (
          <span>
            {row.original.dateOfBirth &&
              moment(row.original.dateOfBirth).format("DD/MM/YYYY")}
          </span>
        );
      },
    },
    {
      Header: "Date of Joining",
      Cell: ({ row }) => {
        return (
          <span>
            {row.original.joiningDate &&
              moment(row.original.joiningDate).format("DD/MM/YYYY")}
          </span>
        );
      },
    },
    {
      Header: "Files",
      Cell: ({ row }) => {
        return (
          <div className="flex flex-col">
            {row.original.identityProof.map((ele) => (
              <a
                className="text-indigo-500 cursor-pointer underline"
                href={`${baseUrl}${ele.file}`}
                key={ele._id}
              >
                {ele.name}
              </a>
            ))}
          </div>
        );
      },
    },
    {
      Header: "Notes",
      accessor: "notes",
    },
    {
      Header: "Role",
      accessor: "role",
    },
    {
      Header: "Action",
      Cell: ({ row }) => {
        return (
          <div className="flex gap-2 items-center">
            <Trash
              onClick={async () => {
                await dispatch(deleteUser({ id: row.original._id }));
                dispatch(fetchUsers());
              }}
              color="red"
            />
          </div>
        );
      },
    },
  ];
  const data = useMemo(() => (users?.docs ? users.docs : []), [users]);
  console.log(data)
  useEffect(() => {
    dispatch(fetchUsers());
  }, [page]);
  return (
    <div className="p-4">
      <TableWithHeadingAndGlobalSearch
        loading={loading}
        data={data}
        searchFunction={debouncedSearch}
        columns={cols}
      />
      <PaginationClassic setPage={setPage} paginationDetails={users} />
    </div>
  );
};

export default ViewUsers;
